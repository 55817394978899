import { IonPage } from '@ionic/react';
import SpaceForHeader from "../../components/header/SpaceForHeader";
import ThemedText from "../../components/ThemedText";
import Footer from "../../components/footer/Footer";
import { IonContent, IonGrid } from "@ionic/react";

export default function Impressum() {
    return (
        <IonPage className="page-design">
            <IonContent>
                <SpaceForHeader />
                <IonGrid style={{ padding: "40px", paddingTop: "0px" }}>
                    <ThemedText variant="h1-dark">Impressum</ThemedText>
                    <p>
                        <strong>Diensteanbieter:</strong>
                        <br />
                        Krameda Alltagshilfe OHG
                        <br />
                        Winterstraße 8, 81543 München
                    </p>
                    <p>
                        <strong>Kontaktmöglichkeiten:</strong>
                        <br />
                        Telefon: 089 24407715<br />
                        E-Mail: team@krameda.de
                    </p>
                    <p>
                        <strong>Vertreten durch die Gesellschafter:</strong>
                        <br />
                        Frau Kristin Schermer
                        <br />
                        Herr Dennis Stanielewitz
                        <br />
                        Herr Mario Faschinger
                    </p>
                    <p>
                        <strong>Gewerbeanmeldung:</strong>
                        <br />
                        Die Gewerbeanmeldung wurde am 31.05.2024 durch die Stadt München erteilt.
                    </p>
                    {/* <p>
                        Umsatzsteueridentifikationsnummer: [bitte ergänzen]
                    </p> */}
                    <p>
                        <strong> Online-Streitbeilegung (Information nach § 36 VSBG):</strong>
                        <br />
                        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit.
                        <br />
                        Link zur OS-Plattform gemäß Art.14 Abs.1 der Verordnung EU Nr. 524/2013: <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>.
                        <br />
                        Der Diensteanbieter ist weder gesetzlich verpflichtet noch auf freiwilliger Basis dazu bereit, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                    </p>
                    <p>
                        Verantwortlicher für den journalistisch-redaktionell gestalteten Inhalt der Webseite im Sinne des Medienstaatsvertrages: Dennis Stanielewitz
                    </p>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    );
}
