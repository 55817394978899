import React, { useRef } from 'react';
import { Divider } from '@mui/material';
import { IonButtons,IonGrid,  IonMenuButton, IonText, IonItem, IonList, IonCol, IonToolbar, IonTitle, IonContent, IonMenu } from '@ionic/react';
import "../HeaderBar.css";
import "../../../CSS/globalStyles.css";
import MenuShoppingCart from './MenuShoppingCart';

export default function BurgerMenue() {
  // Ref für das IonMenu
  const menuRef = useRef(null);

  async function handleItemClick(){
    if (menuRef.current) {
      await menuRef.current.close(); 
    }
  };

  return (
    <>
      <IonGrid style={{ display: "flex", justifyContent: "end",   alignItems: "center", height: "100px" }}>
        <div className="burgerMenue" id="main-content">
          <IonButtons>
            <IonMenuButton />
          </IonButtons>
        </div>
        <IonMenu ref={menuRef} side="end" content-id="main-content" className='headerMenue'>
          <IonToolbar>
            <IonTitle className="ionTitle-menu">Menü</IonTitle>
          </IonToolbar>
          <Divider />
          <IonContent>
            <IonList>
              <IonItem routerLink="/" onClick={handleItemClick}>
                <IonText>Startseite</IonText>
              </IonItem>
              <IonItem routerLink="/portfolio" onClick={handleItemClick}>
                <IonText>Anfragen und Preise</IonText>
              </IonItem>
              <IonItem routerLink="/contact" onClick={handleItemClick}>
                <IonText>Kontakt</IonText>
              </IonItem>
              <IonItem style={{ marginTop: "50px" }}>
                <IonCol style={{ maxWidth: "120px" }} onClick={handleItemClick}>
                  <MenuShoppingCart />
                </IonCol>
                <IonCol></IonCol>
              </IonItem>
              <IonItem routerLink="/checkout" onClick={handleItemClick}>
                <IonText>Checkout</IonText>
              </IonItem>
            </IonList>
          </IonContent>
        </IonMenu>
      </IonGrid>
    </>
  );
}
