import React from 'react';
import { IonInput, IonCol, IonRow } from '@ionic/react';



export default function ContactField() {
    
    const formInput = [
        { label: "Vorname", type: "text", name: "firstname", required: false },
        { label: "Nachname", type: "text", name: "surname", required: false },
        { label: "Postleitzahl", type: "text", name: "plz", required: false },
        { label: "Straße", type: "text", name: "street", required: false },
        { label: "Geburtsdatum", type: "date", name: "bday", required: false },
        { label: "Email*", type: "email", name: "email", required: true}
    ];


    return (
        <IonRow style={{ padding: "25px" }}>
        {formInput.map((input, index) => (
            <IonCol key={index} sizeLg="6" sizeMd='6' sizeSm='6' sizeXs='12'>
                <IonInput labelPlacement="floating" fill="outline" label={input.label} type={input.type} name={input.name} required={input.required}/>
            </IonCol>
        ))}
        <IonRow>
            <p style= {{fontSize: "10px"}}>* diese Angabe ist notwendig, um mit Ihnen in Kontakt zu treten.</p>
        </IonRow>
    </IonRow>
    )
}