import React, { useRef } from 'react';
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import SpaceForHeader from "../../components/header/SpaceForHeader";
 //utils
import { blocks } from '../../utilities/helptasks';
//Caregiver
// import KrissNoBg from "../../SVG/Selbstvorstellungen/KrissNoBg2.png"
import KrissNoBg from "../../SVG/Selbstvorstellungen/KrissNoBg1.png"
import MarioNoBg from "../../SVG/Selbstvorstellungen/MarioNoBg.png"
import DennisNoBg from "../../SVG/Selbstvorstellungen/DennisNoBg2.png"


import { Link } from "react-router-dom";


import "./hilfsleistungenDetails.css";

//ANIMATION
import { useEffect } from 'react';

import { fadeInToLeft, fadeInToRight} from '../../utilities/animation';
import Footer from '../../components/footer/Footer';


const textIfSthMissing = "Sollte Ihnen etwas fehlen, sprechen Sie uns gerne an!";

const caregiverProfil = {
    dennis: DennisNoBg,
    mario: MarioNoBg,
    kristin: KrissNoBg
};

function getRandomCaregiverProfile() {
    const profiles = Object.values(caregiverProfil);
    const randomIndex = Math.floor(Math.random() * profiles.length);
    return profiles[randomIndex];
};


export default function HilfsleistungenDetails() {
    const currentURL = window.location.href;
    const parts = currentURL.split("/");
    const idHilfsleistung = parts[parts.length - 1]

    const imgCaregiverRef = useRef(null);
    const textRef = useRef(null);

    const [isVisible, setIsVisible] = React.useState(false);

    let selectedBlock = blocks.find(block => block.id === idHilfsleistung);

    if (!selectedBlock) {
        selectedBlock = blocks[0];
    }

    useEffect(() => {
        const randomProfile = getRandomCaregiverProfile();
        selectedBlock.caregiver = randomProfile;
    
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.target === textRef.current && entry.isIntersecting) {
                        setIsVisible(1);
                    }
                    if (entry.target === imgCaregiverRef.current && !entry.isIntersecting) {
                        setIsVisible(0);
                    }
                });
            },
            {
                threshold: 0.5
            }
        );
    
        const tRef = textRef.current;
        const iCRef = imgCaregiverRef.current;
    
        if (tRef) observer.observe(tRef);
        if (iCRef) observer.observe(iCRef);
    
        return () => {
            if (tRef) observer.unobserve(tRef);
            if (iCRef) observer.unobserve(iCRef);
        };
    }, [selectedBlock]);
    
    



    useEffect(() => {
        const fadeInToRight_ImgRef = fadeInToRight(textRef);
        const fadeInToLeft_TextRef = fadeInToLeft(imgCaregiverRef);

            if (isVisible) {
                fadeInToRight_ImgRef.play();
                fadeInToLeft_TextRef.play();
            } 
            // else {
            //     fadeOutToLeft_ImgRef.play();
            //     fadeOutToRight_TextRef.play();
            // }
        }, [isVisible]);
    
    return (
        <IonPage className="page-design">
            <SpaceForHeader />
            <IonContent >
                <IonGrid style={{ justifyContent: "center", padding: 0, margin: 0, }}>
                    <IonRow style={{ width: "100%", padding: 0, margin: 0, background: "#0a2351", display: "flex", justifyContent:"center", alignItems:"center"}}> {/* Brewers Blue */}
                        <IonCol sizeXs='12' sizeXl = "5" style = {{ margin:"0", padding: "20px", marginTop: "20px", display: "flex", justifyContent:"center"}}>
                            <img className = "hilfsleistungenDetails-img" src={selectedBlock.image} alt="Personentransport" style={{ }} />
                        </IonCol>
                        <IonCol sizeXs='12' sizeXl = "6" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <p className="big-keyword">{selectedBlock.schlagwort}</p>
                        </IonCol>
                    </IonRow>
                    {/* TEXTBLOCK */}
                    <IonRow  style={{ minHeight: "700px", width: "100%", margin: 0, display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                        <IonRow className="box">
                        <IonCol sizeXs='12' sizeXl='6' style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", padding: 0, margin: 0 }}>
                                <img  ref={imgCaregiverRef} src={selectedBlock.caregiver} alt="Personentransport" style={{ width: "auto", height: "clamp(250px, 50vw, 650px)", objectPosition: "center center", padding: 0, margin: 0}} />
                            </IonCol>
                            <IonCol ref={textRef} sizeXs='12' sizeXl='6' style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "justify", flexDirection: "column", paddingRight: "20px", paddingLeft: "20px"}}>
                                <p className = "p-selectedBlock-shorttext">{selectedBlock.shortText}</p>
                                <p className = "p-selectedBlock-longtext">{selectedBlock.longText}</p>
                                <br/>
                                {selectedBlock.bulletPoints && selectedBlock.bulletPoints.map((bulletPoint, index) => {
                                    return (
                                    <IonGrid style = {{width: "100%", maxHeight: "25px"}}>
                                    <li className = "p-selectedBlock-bulletpoint" key={index}>
                                        {bulletPoint}
                                        </li>
                                    </IonGrid>
                                    )
                                })
                                }
                                <br/>
                                <br/>

                                <p className = "p-selectedBlock-missing">{textIfSthMissing}</p>
                                <br/>
                                <IonRow style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "justify" }}>
                                    <IonCol sizeXs="6" sizeXl="6" >
                                        <Link to="/contact">
                                            <IonButton style={{ width: "100%", height: "15%", minHeight: "45px", maxHeight: "65px", whiteSpace: "normal", wordWrap: "break-word" }} className="btn">
                                                Rückfrage
                                            </IonButton>
                                        </Link>
                                    </IonCol>
                                    <IonCol sizeXs="6" sizeXl="6" >

                                        <Link to="/portfolio">
                                            <IonButton style={{  width: "100%",  height: "15%", minHeight: "45px", maxHeight: "65px", whiteSpace: "normal" }} className="btn">
                                                Buchen
                                            </IonButton>
                                        </Link>
                                    </IonCol>



                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonRow>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    )
}