import React  from 'react';
import { Link } from "react-router-dom";
import {IonRow, IonCol } from '@ionic/react';

import "../HeaderBar.css";
import "../../../CSS/globalStyles.css";


import MenuShoppingCart from './MenuShoppingCart';

export default function HeaderContent() {

  const headerLinks = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Anfragen und Preise",
      path: "/portfolio",
    },
    {
      title: "Kontakt",
      path: "/contact",
    },
    {
      title: "Warenkorb",
      path: "/portfolio",
    }
  ];

  const menuAccount = document.getElementById('ion-menu-account');
  console.log(menuAccount)

  return (

        <IonRow style={{ padding: 0, margin: 0, display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%" }}>
          <IonCol style={{ width: "50%", padding: 0, margin: 0, display: "flex", justifyContent: "flex-end", gap: "20px", paddingRight: "20px"}}>
            {headerLinks.map((link, index) => (
              <Link to={link.path} className="headerConent-link" style={{ textDecoration: "none", margin: "0 5px" }}>
                {link.title === "Warenkorb" ?
                  <MenuShoppingCart />
                  :
                  <p className='p-headerLinks'>{link.title}</p>
                }
              </Link>
            ))}
          </IonCol>
        </IonRow>
  )
}
