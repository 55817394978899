import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: null,
        token: null,  
        userPic: null,
    },
    reducers: {
        setUser: (state, action) => {
            const { user, token } = action.payload || {}; 
            state.currentUser = user;
            state.token = token;
        },
		updateUser: (state, action) => {
			const { key, value } = action.payload || {};
			if (key !== undefined && value !== undefined)
			{
				state.currentUser[key] = value;
			}
		}
    },
});

export const { setUser, updateUser } = userSlice.actions;
export default userSlice.reducer;