import { IonContent, IonList, IonPage } from "@ionic/react";
import { useSelector } from "react-redux";
import Footer from "../../components/footer/Footer";
import ThemedText from "../../components/ThemedText";
import SpaceForHeader from "../../components/header/SpaceForHeader";

import "./checkout.css"
import { IonItem, IonButton, IonRow, IonCol, IonLabel } from '@ionic/react';
import HorizontalCard from "./HorizontalCard";
import { Divider } from "@mui/material";

import ContactField from "./ContactField";

export default function CheckOut() {
    const shoppingCart = useSelector(state => state.shoppingCart.shoppingCartState);

    function totalCost() {
        let total = 0.00;
        shoppingCart.forEach((orderItem) => {
            total += parseFloat(orderItem.totalPrice)
        });
        return total.toFixed(2);
    }

    async function booking(e) {
        e.preventDefault();
    
        const formData = new FormData(e.target);
    
        const bookingData = {
            firstname: formData.get("firstname") || "no value",
            surname: formData.get("surname") || "no value",
            plz: formData.get("plz") || "no value",
            street: formData.get("street") || "no value",
            bday: formData.get("bday") || "no value",
            email: formData.get("email"),
            totalprice: totalCost() || 0,
            brutto: (totalCost() * 1.19).toFixed(2) || "0.00",
            shoppingcart: shoppingCart || ["no value"],
            subject: 'Neuer Auftrag!', // Betreff der E-Mail
        };
    
        // Hier wird bookingData in das richtige Format gebracht
        const requestData = { data: bookingData };

        try {
            const response = await fetch('https://Krameda.de/api/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData) // JSON mit dem "data"-Objekt
            });

            if (response.status === 200) {
                alert('Vielen Dank! Wir werden uns schnellstmöglich bei Ihnen melden. :)');
                window.location.href = "/"; // Redirect zur Startseite
            } else {
                console.error("Fehler beim Senden der E-Mail:", response.error);
            }
        } catch (error) {
            console.error("Fehler:", error);
        }
    }
    

    return(
        <IonPage className="page-design">
            <IonContent>
                <SpaceForHeader/>
                    <ThemedText variant = "h1-dark">CheckOut</ThemedText>
                    {shoppingCart && shoppingCart.length > 0 ? (
                    shoppingCart.map((orderItem, index) => (
                        <HorizontalCard orderItem={orderItem} key={index}/>
                    ))
                    ) : (
                        <IonItem style = {{display: "flex", justifyContent: "center", selfAlign: "center"}}>
                            <IonLabel style = {{display: "flex", justifyContent: "center", selfAlign: "center", paddingBottom: "25px", fontSize: "clamp(14px, 3vw, 28px)"}}>Keine Aufträge im Warenkorb</IonLabel>
                        </IonItem>
                    )}
                    <Divider />
                    <IonRow>
                        <IonCol>
                            <div style = {{display:"flex", justifyContent:"flex-start"}}>
                            <ThemedText variant = "h2-dark">Gesamt Rechnung</ThemedText>
                            </div>
                        <IonList>
                            <IonItem>
                                <IonLabel>Netto</IonLabel>
                                <IonLabel>{totalCost()} €</IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Umsatzsteuer</IonLabel>
                                <IonLabel>{(totalCost() * 0.19).toFixed(2)} €</IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Gesamtbetrag</IonLabel>
                                <IonLabel>{(totalCost() * 1.19).toFixed(2)} €</IonLabel>
                            </IonItem>
                        </IonList>
                    </IonCol>
                </IonRow>
                <IonCol>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <ThemedText variant="h2-dark">Kontakt Formular</ThemedText>
                    </div>
                    <form onSubmit={booking}>
            <ContactField />
            <IonButton type="submit" style={{ padding: "50px 25px" }} expand="block" color="success">
                Anfrage senden
            </IonButton>
        </form>
                </IonCol>
                <Footer />
            </IonContent>
        </IonPage>

    );
}