import iconShoppingCart from "../../../SVG/Icon/shoppingCart.png"
import { IonGrid } from '@ionic/react';
import CartCounter from './CartCounter';
import {  IonHeader} from '@ionic/react';
import { IonButton } from '@ionic/react';
import { IonCard } from '@ionic/react';
import Drawer from '@mui/material/Drawer';
import React from 'react';
import { IonRow, IonCol } from '@ionic/react';
import { IonItem, IonLabel } from '@ionic/react';
import { useSelector } from "react-redux";
import { IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/react';
import ThemedText from "../../ThemedText";
import { useDispatch } from 'react-redux';
import { deleteItemCart} from '../../../slices/shoppingCartSlice';
import { Link } from "react-router-dom/cjs/react-router-dom";
import "./menushoppingcart.css";

export default function MenuShoppingCart() {
    const [open, setOpen] = React.useState(false);
    const shoppingCart = useSelector(state => state.shoppingCart.shoppingCartState);
    const dispatch = useDispatch();

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
      };
      
      
    function deleteItem(orderItem) {
        console.log(orderItem)
        console.log("deleteItem");
        dispatch(deleteItemCart(orderItem));
    }
    const DrawerList = (

        <IonGrid className="drawerlist-grid">
            <IonHeader className="drawerlist-header">
                Warenkorb
            </IonHeader>
                {shoppingCart && shoppingCart.length > 0 ? (
                    shoppingCart.map((orderItem, index) => (
                        <IonCard style = {{margin: "30px"}}color={"dark"} key={index}>
                            <div style ={{display: "flex", justifyContent: "center"}}>
                                <img className = "drawer-orderitem-img" src={orderItem.img} alt={orderItem.segment} />
                            </div>
                            <IonCardHeader>
                                <IonCardTitle>{orderItem.segment}</IonCardTitle>
                                <IonCardSubtitle style = {{padding: "0px", margin: "0px"}}>{orderItem.price} €
                                {orderItem.priceAdding && ` ${orderItem.priceAdding}`}</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                                    <IonLabel>
                                       <p className = "p-list-label">Anmerkungen</p> {orderItem.orderInfo ? orderItem.orderInfo : "Keine Anmerkungen"}
                                        <br/>
                                        <p className = "p-list-label">Datum</p> {new Date(orderItem.startDateTime).toLocaleDateString()} 
                                        <br/>
                                        <p className = "p-list-label">Startzeitpunkt</p> {new Date(orderItem.startDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} Uhr
                                        <br/>
                                        <p className = "p-list-label">Buchende Stunden</p> {orderItem.hours} {orderItem.hours > 1 ? "Stunden": "Stunde"}
                                    </IonLabel>
                                    <br/>
                                    <IonRow style = {{justifyContent:"flex-end", alignItems:"center"}}>
                                        <IonCol sizeXs = "12" size = "6" style = {{padding: 0, margin: 0, paddingTop:"10px"}}>
                                            <ThemedText variant = "p-bright">Preis: {orderItem.totalPrice} € </ThemedText>
                                        </IonCol>
                                        <IonCol sizeXs = "12" size = "6" style = {{ display: "flex", justifyContent:"flex-end"}}>
                                            <IonButton color={"danger"} style = {{width: "fit-content", fontSize: "clamp(10px, 3vw, 14px" }} onClick={() => deleteItem(orderItem)}>Entfernen</IonButton>
                                        </IonCol>
                                    </IonRow>
                            </IonCardContent>
                        </IonCard>
                            ))
                    ) : (
                        <IonItem>
                            <IonLabel>No items in cart.</IonLabel>
                        </IonItem>
                    )}
                    <div style = {{display: "flex", justifyContent: "center", alignItems: "center", padding: "20px"}}>
                        <Link to="/checkout" style={{ textDecoration: "none", width: "100%",display: "flex", justifyContent: "center" }}>
                            <IonButton  onClick={toggleDrawer(false)} size="large" style = {{color: "white"}}>Zur Kasse</IonButton>
                        </Link>
                    </div>
        </IonGrid>
    );



    return (

        <>

        <IonGrid className="col-shoppingCart" onClick={toggleDrawer(true)}>
            <img className="img-shopping-cart" src={iconShoppingCart} alt="shopping cart" />
            <CartCounter />
            <p className='p-headerLinks'>Warenkorb</p>
        </IonGrid>

        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}

        </Drawer>

        </>


    );
}
