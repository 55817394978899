import React from 'react';
import { Link } from 'react-router-dom';
import { IonGrid, IonRow, IonCol } from '@ionic/react';

import "./footer.css";

export default function Footer() {
  return (
    <IonGrid className='footerStyle'>
      <IonRow style = {{justifyContent: "flex-start"}}>
        <IonCol size = "6">
          <Link to="/datenschutz" className="link">
            Datenschutz
          </Link>
        </IonCol>
        <IonCol size = "6">
          <Link to="/impressum" className="link" >
            Impressum
          </Link>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

