import { IonGrid, IonCol, IonRow} from '@ionic/react';


import "./deepinfoskrameda.css"

//Images 
// import personentransport from "../assets/DeepInfosKrameda/personenTransport.jpg";
// import sozialeInteraktion from "../assets/DeepInfosKrameda/sozialeInteraktion.jpg";
// import tierpflege from "../assets/DeepInfosKrameda/tierpflege.jpg";
// import begleitung from "../assets/DeepInfosKrameda/begleitung.jpg";
// import haushalt from "../assets/DeepInfosKrameda/haushalt.jpg";
// import technik from "../assets/DeepInfosKrameda/technik.jpg";
import beschaffung from "../assets/DeepInfosKrameda/beschaffung.png";
import sozialeInteraktion from "../assets/DeepInfosKrameda/SI3.jpg";
import tierpflege from "../assets/DeepInfosKrameda/tierpflege1.jpg";
import begleitung from "../assets/DeepInfosKrameda/begleitung.jpg";
import haushalt from "../assets/DeepInfosKrameda/Garten2.jpg";
import technik from "../assets/DeepInfosKrameda/technik2.jpg";

import { Link } from 'react-router-dom'; 

export default function DeepInfosKrameda() {

    const blocks = [
        {
            id: "sozialeInteraktion",
            image: sozialeInteraktion,
            text: "Wir sind da",
            schlagwort: "Soziale Interaktion",
            link: "/hilfsleistungen/sozialeInteraktion"
        },
        {
            id: "haushalt",
            image: haushalt,
            text: "Wir machen den Haushalt",
            schlagwort: "Haushalt",
            link: "/hilfsleistungen/haushalt"
        },
        {
            id: "begleitung",
            image: begleitung,
            text: "Wir begleiten Sie",
            schlagwort: "Begleitung",
            link: "/hilfsleistungen/begleitung"
        },
        {
            id: "beschaffung",
            image: beschaffung,
            text: "Wir bringen Besorgungen",
            schlagwort: "Beschaffung",
            link: "/hilfsleistungen/beschaffung"
        },
        {
            id: "tierpflege",
            image: tierpflege,
            text: "Wir kümmern uns",
            schlagwort: "Tierpflege",
            link: "/hilfsleistungen/tierpflege"
        },    
        {
            id: "technik",
            image: technik,
            text: "Wir helfen dabei",
            schlagwort: "Technik",
            link: "/hilfsleistungen/technik"
        }
    ];

    return (
        <IonGrid className = "background-DeepInfosKrameda" >
            <IonGrid style = {{paddingTop:"100px", margin:0, maxHeight: "5%"}}>
                <p className = "section-title">Willkommen bei Krameda!</p>
                <p className = "subtilte" style = {{padding: 0, margin: 0}}><strong>Mehr Zeit für die wichtigen Momente gewinnen.</strong></p> 
                    <p className = "subtilte" style = {{padding: 0, margin: 0}}>Anstatt selbst den Haushalt zu erledigen, investieren Sie beispielsweise 20 € pro Stunde und gewinnen wertvolle Zeit mit Ihren Liebsten. Kein Hetzen, kein vorzeitiges Verlassen der Arbeit oder zusätzliche Wege – einfach mehr unbeschwerte Momente, zum Beispiel mit Ihren Eltern.</p>
                    <p className = "subtilte" style = {{fontSize: "clamp(16px, 2vw, 30px", fontWeight: 700}}>Wir helfen gerne!</p>
            </IonGrid>
            <IonGrid style={{ height: "100%", width: "100%", padding: 0, margin: 0}}>
                {
                    blocks.map((element, index) => ( 
                        index % 3 === 0 && ( //Index % 3 um jede 3. Zeile eine neue IonRow zu erstellen
                            <IonRow key={index} className = "row-interactive-images">
                                {blocks.slice(index, index + 3).map((item, subIndex) => ( //Wähl di enächstne drei elemente aus - iteriere durch jedes dieser drei und erstelle IonCol für jedes
                                    <IonCol key={subIndex} sizeXs='4' sizeMd='3.5' sizeLg="3.3">
                                        <IonGrid className = "grid-interactive-image">
                                        <Link to={item.link} style = {{textDecoration: "none"}}>
                                            <IonGrid className="interactive-image" style={{ backgroundImage: `url(${item.image})` }}>
                                                <IonGrid className="interactive-image-overlay">
                                                    <p className="interactive-image-text">{item.text}</p>
                                                </IonGrid>
                                            </IonGrid>
                                            <p className="interactive-image-schlagwort">{item.schlagwort}</p>
                                        </Link>
                                        </IonGrid>
                                    </IonCol>
                                ))}
                            </IonRow>
                        )
                    ))}
            </IonGrid>
        </IonGrid>
    );
}
