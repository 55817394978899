import React from 'react';
import { IonGrid, IonRow, IonCol} from "@ionic/react";
import { Link } from 'react-router-dom';

export default function FooterForPortfolio() {
    return(
        <IonGrid className='style-footer-portfolio'>
        <IonRow style={{ justifyContent: "flex-end" }}>
            <IonCol>
                <Link to="/datenschutz" className="link">
                    Datenschutz
                </Link>
            </IonCol>
            <IonCol>
                <Link to="/impressum" className="link" >
                    Impressum
                </Link>
            </IonCol>
        </IonRow>
    </IonGrid>
    )
}