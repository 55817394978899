import * as React from 'react';

import sceLogo from '../../../SVG/Cooperations/Split/1.svg';
import hmLogo from '../../../SVG/Cooperations/Split/2.svg';
import startUpLeague from '../../../SVG/Cooperations/Split/3.svg';
import startUpCertificate from '../../../SVG/Cooperations/Split/4.svg';
import zeidlerCertificate from '../../../SVG/Cooperations/Split/5.svg';
import "../../../CSS/globalStyles.css";

import { IonGrid } from '@ionic/react';

export default function Banner() {
    return (
        <IonGrid style={{padding: 0, margin:0, borderTop: "5px solid #012169", borderBottom: "5px solid #012169" }}>
            <IonGrid>
                <p className= "section-title">UNSERE SPONSOREN</p>
            </IonGrid>
            <div >
                <div>
                    <div className="cooperationsBanner">
                        <img src={sceLogo} alt="SCE Logo" />
                        <img src={hmLogo} alt="Hochschule München Logo" />
                        <img src={startUpLeague} alt="'Start Up Leage'Logo" />
                        <img src={startUpCertificate} alt="'StartUp Certificate' Logo" />
                        <img src={zeidlerCertificate} alt="'Zeidler Forschungsstifung' Logo" />
                        <img src={sceLogo} alt="SCE Logo" />
                        <img src={hmLogo} alt="Hochschule München Logo" />
                        <img src={startUpLeague} alt="'Start Up Leage'Logo" />
                        <img src={startUpCertificate} alt="'StartUp Certificate' Logo" />
                        <img src={zeidlerCertificate} alt="'Zeidler Forschungsstifung' Logo" />
                        <img src={sceLogo} alt="SCE Logo" />
                        <img src={hmLogo} alt="Hochschule München Logo" />
                        <img src={startUpLeague} alt="'Start Up Leage'Logo" />
                        <img src={startUpCertificate} alt="'StartUp Certificate' Logo" />
                        <img src={zeidlerCertificate} alt="'Zeidler Forschungsstifung' Logo" />
                    </div>
                </div>
            </div>
        </IonGrid>

    );
}