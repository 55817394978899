//REDUX
import React from 'react';

//IONIC
import '@ionic/react/css/core.css';
import { setupIonicReact } from '@ionic/react';

//Portfolio
import Portfolio from './pages/portfolio/Portfolio';
import Contact from './pages/contact/Contact';
import Homepage from './pages/homePage/HomePage';

import { Route } from 'react-router-dom'; 



//Layout
import RootLayout from './layout/RootLayout';

import { IonReactRouter } from '@ionic/react-router';

import HilfsleistungenDetails from './pages/hilfsleistungen/HilfsleistungenDetails';
import Impressum from './pages/datenschutz-impressum/Impressum';
import Datenschutz from './pages/datenschutz-impressum/Datenschutz';
import CheckOut from './pages/checkout/CheckOut';


setupIonicReact();

const blocks = [
  {
      link: "/hilfsleistungen/beschaffung",
      component: HilfsleistungenDetails
  },
  {
      link: "/hilfsleistungen/sozialeInteraktion",
      component: HilfsleistungenDetails
  },
  {
      link: "/hilfsleistungen/tierpflege",
      component: HilfsleistungenDetails
  },
  {
      link: "/hilfsleistungen/begleitung",
      component: HilfsleistungenDetails
  },
  {
      link: "/hilfsleistungen/haushalt",
      component: HilfsleistungenDetails
  },
  {
      link: "/hilfsleistungen/technik",
      component: HilfsleistungenDetails
  }
];



function App() {
  return (
    <IonReactRouter>
      <RootLayout>
        <Route path="/" exact component={Homepage} />
        <Route path="/portfolio" exact component={Portfolio} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/impressum" exact component={Impressum} />
        <Route path="/datenschutz" exact component={Datenschutz} />
        <Route path="/checkout" exact component={CheckOut} />
        {blocks.map((item, index) => (
          <Route key={index} path={item.link} exact component={item.component} />
        ))}

      </RootLayout>



    </IonReactRouter>
  );
}

export default App;

