import { createSlice } from '@reduxjs/toolkit'; //1. Import function

const coordinateSlice = createSlice({           //2. create const, that creates a Slice
    name: 'coordinate',                          //3. name of the slice
    initialState: {                             //4. initial state
        currentCoordinate: null,                //5. currentCoordinate is null
    },
    reducers: {                                //6. reducers
        setCoordinate: (state, action) => {   //7. setCoordinate
            const { coordinate } = action.payload || {}; //8. get coordinate from action.payload
            state.currentCoordinate = coordinate; //9. set currentCoordinate to coordinate
        }
    },
});

export const { setCoordinate } = coordinateSlice.actions; //10. export setCoordinate
export default coordinateSlice.reducer; //11. export default reducer
