import { IonContent, IonGrid,  IonRow, IonPage} from '@ionic/react';
import React, { useEffect } from 'react';
import { useState} from 'react';
import { Link } from 'react-router-dom';
import SpaceForHeader from '../../components/header/SpaceForHeader';
import ThemedText from '../../components/ThemedText';

import './portfolio.css';

import { setShoppingCart } from '../../slices/shoppingCartSlice';
import { useDispatch } from 'react-redux';
import { Divider } from '@mui/material';

import ProductCase from './components/ProductCase';
import FooterForPortfolio from './components/FooterForPortfolio';

//utils
import { products } from '../../utilities/helptasks';

export default function Portfolio() {

    const [warenkorb] = useState([]);


    const dispatch = useDispatch();


    useEffect(()=>{
        dispatch(setShoppingCart(warenkorb))
    }, [warenkorb, dispatch])

    return (
        <IonPage className="page-design">
            <IonContent >
                <SpaceForHeader />
                <IonGrid className="background">
                    <Divider> 
                        <ThemedText variant="h1-dark">Hilfsbuchung</ThemedText>
                    </Divider>
                    <IonGrid className = "grid-hilfstext">
                    <p style = {{fontWeight: 500}}>Wählen Sie aus den folgenden Kategorien aus, um Ihre passende Hilfe zu finden!</p>
                    <br/>
                    <p>Unsere Dienstleistungen lassen sich flexibel kombinieren, um optimal auf Ihre individuellen Anforderungen einzugehen. Wir empfehlen jedoch, zusätzlichen Arbeitsaufwand mit Bedacht einzuplanen, da dies möglicherweise zusätzliche Stunden in Anspruch nehmen könnte. In einem solchen Fall würden wir uns natürlich rechtzeitig mit Ihnen in Verbindung setzen.</p>
                    <p>Sollten Sie in unserem Angebot nicht das finden, wonach Sie suchen, zögern Sie nicht, uns </p>
                            <strong className="p-contact"><Link className="no-decoration" to="/contact">direkt zu kontaktieren!</Link>
                            </strong>
                            
                    </IonGrid>
                    <Divider> 
                        <ThemedText variant="h2-dark">PREISE</ThemedText>
                    </Divider>
                    <IonRow className = "row-products">
                        {products.map(product => (
                            <ProductCase key={product.segment} product={product} />
                        ))}
                    </IonRow>
                </IonGrid>
                {/* INDIVIDUALIZED FOOTER! */}
                <FooterForPortfolio />
            </IonContent>
        </IonPage>
    );
}


// TODO: Uhrzeit + datum einfügen in anfrage erstellen 