
import begleitung from "../pages/homePage/assets/DeepInfosKrameda/begleitung.jpg";
import sozialeInteraktion from "../pages/homePage/assets/DeepInfosKrameda/SI3.jpg";
import tierpflege from "../pages/homePage/assets/DeepInfosKrameda/tierpflege1.jpg";
import beschaffung from "../pages/homePage/assets/DeepInfosKrameda/beschaffung.jpg";
import haushalt from "../pages/homePage/assets/DeepInfosKrameda/Garten2.jpg";
import technik from "../pages/homePage/assets/DeepInfosKrameda/technik2.jpg";



export const products = [
    
    {
        img: sozialeInteraktion,
        segment: "Soziale Interaktionen",
        price: 20, // 20€ pro Stunde
        priceAdding: "pro Stunde",
        subtasks: [
            { id: 7, taskname: "Aktivitäten (z.B. Wandern, Ausflüge)" },
            { id: 8, taskname: "Gemeinsames Kochen" },
            { id: 9, taskname: "Spaziergänge" },
            { id: 10, taskname: "Spiele und Unterhaltung" },
            { id: 12, taskname: "Gedächtnisspiel (Kreuzworträtsel, Memory etc.)" }
        ],
        details: [
            { question: "Gibt es eine präferierte Aktivität?", type: "text" },
            { question: "Wie viele Personen nehmen an der Aktivität teil?", type: "number" },
            { question: "Sind nennswerte physische Beeinträchtigungen vorhanden?", type: "text" },
            { question: "Sind nennswerte psychische Störungen vorhanden?", type: "text" },
            { question: "Rabattcode (wird verrechnet nach dem Eingang der Anfrage)", type: "text" },
        ]
    },
    {
        img: haushalt,
        segment: "Haushalt",
        price: 20, // 20€ pro Stunde
        priceAdding: "pro Stunde",
        subtasks: [
            { id: 25, taskname: "Tägliche Hausarbeiten" },
            { id: 26, taskname: "Bügelservice" },
            { id: 27, taskname: "Unterstützende Garten- und Pflanzenarbeit" },
            { id: 29, taskname: "Staubsaugen und Wischen" },
            { id: 30, taskname: "Kochservice" },
        ],
        details: [
            { question: "Sind die benötigten Utensilien für den Haushalt vorhanden?", type: "text" },
            { question: "Wie viele Personen leben im Haushalt?", type: "number" },
            { question: "Wie groß ist die Fläche ihres Haushalts?", type: "number" },
            { question: "Rabattcode (wird verrechnet nach dem Eingang der Anfrage)", type: "text" },
        ]
    },
    {
        img: begleitung,
        segment: "Begleitung",
        price: 20, // 20€ pro Stunde
        priceAdding: "pro Stunde",
        subtasks: [
            { id: 13, taskname: "Kulturelle Veranstaltungen (z.B. Kino)" },
            { id: 15, taskname: "Gemeinsame Einkäufe" },
            { id: 17, taskname: "Begleitung auf Events (z.B. Konzerte)" },
            { id: 18, taskname: "Handwerkliche Aktivitäten (Töpfern, Malen, Stricken, uvm.)" },
        ],
        details:[
            { question: "Wie viele Personen nehmen an der Veranstaltung teil?", type: "number" },
            { question: "Sind nennswerte physische Beeinträchtigungen vorhanden?", type: "text" },
            { question: "Sind nennswerte psychische Störungen vorhanden?", type: "text" },
            { question: "Rabattcode (wird verrechnet nach dem Eingang der Anfrage)", type: "text" },
        ]
    },
    {
        img: beschaffung,
        segment: "Botengänge/ Beschaffungen",
        image: technik,
        priceAdding: "+ Produkt-/Versandpreis oder Briefporto",
        price: "Variierender Preis",
        subtasks: [
            {
                id: 35,
                taskname: "Medikamentenbeschaffung",
                price: 10,
            },
            {
                id: 36,
                taskname: "Blumengestecke",
                price: 10,
            },
            {
                id: 37,
                taskname: "Briefversand",
                price: 5,
            },
            {
                id: 38,
                taskname: "Paketdienst (Abholen, Versenden)",
                price: 10,
            },
            {
                id: 39,
                taskname: "Backwarenbesorgung/ Frühstück",
                price: 10,
            },
            {
                id: 40,
                taskname: "Andere Besorgungen (Bücher, Pakete, Kleidung aus der Reinigung etc)",
                price: 20,
            }
        ],
        details: [
            { question: "Möchten Sie die Besorgung von einem bestimmmten Laden?", type: "text" },
            { question: "Falls es um größere Objekte geht: Wie viel wiegt es? (Wenn nicht, einfach 0 eingeben)", type: "text" },
            { question: "Rabattcode (wird verrechnet nach dem Eingang der Anfrage)", type: "text" },
        ]
    },
    {
        img: tierpflege,
        segment: "Tierpflege",
        price: 20, // 20€ pro Stunde
        priceAdding: "pro Stunde",
        subtasks: [
            { id: 19, taskname: "Hundespaziergang" },
            { id: 20, taskname: "Fütterung" },
            { id: 21, taskname: "Gemeinsame Tierarzttermine" },
            { id: 22, taskname: "Haustiersitting" },
            { id: 23, taskname: "Spielzeit mit Haustieren" },
        ],
        details: [
            { question: "Soll wir mit dem Hund Gassi gehen?", type: "text" },
            { question: "Wie oft soll Ihr Hund gefüttert werden?", type: "number" },
            { question: "Gibt es spezielle Anforderungen an das Futter?", type: "text" },
            { question: "Welche Hunderasse ist Ihr Hund?", type: "text" },
            { question: "Wie alt ist Ihr Hund?", type: "number" },
            { question: "Wie viel wiegt Ihr Hund?", type: "number" },  
            { question: "Hat Ihr Hund Allergien?", type: "text" },
            { question: "Wie verträgt sich Ihr Hund mit anderen Tieren?", type: "text" },
            { question: "Wie verträgt sich das Ihr Hund mit anderen Menschen?", type: "text" },
            { question: "Rabattcode (wird verrechnet nach dem Eingang der Anfrage)", type: "text" },
        ]
    },

    {
        img: technik,
        segment: "Technik",
        price: 20, 
        priceAdding: "pro Stunde",
        subtasks: [
            { id: 1, taskname: "Email schreiben" },
            { id: 2, taskname: "Smartphone einrichten" },
            { id: 3, taskname: "Administration" },
            { id: 4, taskname: "WLAN verbinden" },
            { id: 5, taskname: "Online Termin buchen" },
        ],
        details: [
            { question: "Bei welchem Gerät brauchen Sie Hilfe", type: "text" },
            { question: "Rabattcode (wird verrechnet nach dem Eingang der Anfrage)", type: "text" },
        ]

    },
    


];


export const blocks = [

    {
        id: "sozialeInteraktion",
        image: sozialeInteraktion,
        shortText: "Wir sind da",
        schlagwort: "Soziale Interaktion",
        link: "/hilfsleistungen/sozialeInteraktion",
        longText: "In Momenten der Einsamkeit oder einfach nur, wenn Sie jemanden zum Reden brauchen, stehen wir Ihnen zur Seite. Unsere sozialen Interaktionsdienste bieten nicht nur Gesellschaft, sondern auch Unterhaltung und ein offenes Ohr für Ihre Anliegen. Unter anderem Wir sind für Sie mit folgenden Punkten:",
        bulletPoints: [
            "Aktivitäten",
            "Kochen",
            "Spaziergänge",
            "Gedächtnisspiele und -aktivitäten",
            "Gemeinsames Lesen",
            "Spiele und Unterhaltung",
        ],
    },
    {
        id: "tierpflege",
        image: tierpflege,
        shortText: "Wir kümmern uns",
        schlagwort: "Tierpflege",
        link: "/hilfsleistungen/tierpflege",
        longText: "Ihr Haustier ist mehr als nur ein Begleiter - es ist ein geschätztes Familienmitglied. Wir wissen, wie wichtig es ist, die Bedürfnisse Ihres pelzigen Freundes zu erfüllen. Daher kümmenr wir uns unter anderem um:",
        bulletPoints: [
            "Fütterung",
            "Gassi gehen",
            "Spiel und Spaß",
            "Tierarztbesuche",
            "Pflege und Hygiene",
            "und vieles mehr",
        ],
    },
    {
        id: "begleitung",
        image: begleitung,
        shortText: "Wir begleiten Sie",
        schlagwort: "Begleitung",
        link: "/hilfsleistungen/begleitung",
        longText: "Es gibt Momente, in denen die Gesellschaft eines Begleiters besonders wertvoll ist - sei es für einen Arztbesuch, einen entspannten Einkaufsbummel oder den Besuch einer aufregenden Theatervorstellung. Unsere Begleitdienste sind weit mehr als nur praktische Unterstützung; Wir begleiten sie unter anderem bei:",
        bulletPoints: [
            "Arztbesuchen",
            "Einkäufen",
            "Veranstaltungen",
            "Spaziergängen",
            "Ausflügen",
            "und vieles mehr",
        ],
    },
    {
        id: "haushalt",
        image: haushalt,
        shortText: "Wir machen den Haushalt",
        schlagwort: "Haushalt",
        link: "/hilfsleistungen/haushalt",
        longText: "Ein gepflegter und ordentlicher Haushalt bildet die Basis für ein angenehmes Wohnumfeld, das Ihnen Ruhe und Entspannung schenkt. Unsere Haushaltsdienste kümmern sich um:",
        bulletPoints: [
            "Putzen",
            "Aufräumen",
            "Wäsche waschen",
            "Bügeln",
            "und vieles mehr",
        ],
    },
    {
        id: "technik",
        image: technik,
        shortText: "Wir reparieren",
        schlagwort: "Technik",
        link: "/hilfsleistungen/technik",
        longText: "Für den Fall, dass Sie auf Probleme mit E-Mails oder dem Internet stoßen, stehen wir gerne zur Verfügung, um Ihnen zu helfen. Wir sind zwar keine Experten, aber wir werden unser Bestes tun, um Ihre technischen Herausforderungen zu lösen und Ihnen den bestmöglichen Service zu bieten. Wir richten:",
        bulletPoints: [
            "Computer",
            "Smartphones",
            "Tablets",
            "Internetverbindungen",
            "und vieles mehr ein",
        ],
    },
    {
        id: "beschaffung",
        image: beschaffung,
        shortText: "Wir bringen es",
        schlagwort: "Beschaffung",
        link: "/hilfsleistungen/beschaffung",
        longText: "Die Erledigung kleiner Besorgungen und das Abholen von Dingen können oft eine mühsame Herausforderung darstellen. Dank unserem zuverlässigen Botenservice können Sie sich entspannt zu Hause zurücklehnen, während wir uns um alles kümmern. Wir holen unteranderem für Sie ab:",
        bulletPoints: [
            "Medikamente von der Apotheke",
            "Lebensmittel vom Supermarkt",
            "Kleidung von der Reingung",
            "Rezepte von Arztpraxen",
            "Pakete abholen oder versenden",
            "und vieles mehr",
        ],
    }
];
