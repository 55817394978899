import React from 'react';
import { IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonRow, IonCol, IonButton, IonLabel } from '@ionic/react';
import { deleteItemCart } from "../../slices/shoppingCartSlice";
import { useDispatch } from "react-redux";


export default function HorizontalCard({ orderItem, index }) {
    const dispatch = useDispatch();

    function deleteItem(orderItem) {
        dispatch(deleteItemCart(orderItem));
    }


    return (
        <IonCard className="checkout-card" style={{ margin: "30px" }} color={"light"} key={index}>
            <IonRow style = {{height: "100%"}}>
                <IonCol size="4" sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" style={{ padding: "0px", margin: "0px" }}>
                    <div style={{ backgroundColor: "#002147", display: "flex", justifyContent: "center", height: "100%", alignItems: "center", padding: "0px" }}>
                        <img className="checkout-card-img" src={orderItem.img} alt={orderItem.segment} />
                    </div>
                </IonCol>
                <IonCol sizeXs="12" size="8" sizeSm="12" sizeMd="8" sizeLg="8" style={{ padding: "0px", height: "100%" }}>
                    <IonCardContent className="checkout-card-content" >
                        <IonRow >
                            <IonCol>
                                <IonCardTitle className="checkout-card-title" >
                                    {orderItem.segment}
                                </IonCardTitle>
                                <IonCardSubtitle className="checkout-card-subtitle">
                                    {orderItem.price} € {orderItem.priceAdding && ` ${orderItem.priceAdding}`}
                                </IonCardSubtitle>
                            </IonCol>
                        </IonRow>
                        <IonRow style={{  alignItems: "center", width: "100%" }}>
                            <IonLabel className="checkout-card-labels">
                                <strong>Anmerkungen</strong>: {orderItem.orderInfo ? orderItem.orderInfo : "Keine Anmerkungen"}
                                <br />
                                <strong>Datum</strong>: {new Date(orderItem.startDateTime).toLocaleDateString()}
                                <br />
                                <strong>Startzeitpunkt</strong>: {new Date(orderItem.startDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} Uhr
                                <br />
                                <strong>Buchende  {orderItem.hours > 1 ? "Stunden": "Stunde"}</strong>: {orderItem.hours} {orderItem.hours > 1 ? "Stunden": "Stunde"}
                            </IonLabel>
                        </IonRow>
                        <IonRow style={{  justifyContent: "flex-end", alignItems: "center"}}>
                            <IonCol sizeXs= "12" sizeXl='8' sizeSm = "12" className ="col-price" style={{ padding: 0, margin: 0, }}>
                                <IonLabel className="checkout-card-labels">
                                    <strong>Gesamtpreis für diese Hilfe:</strong>
                                    <br />
                                    {orderItem.price} € x {orderItem.hours} {orderItem.hours > 1 ? "Stunden": "Stunde"} = {orderItem.totalPrice} € 
                                </IonLabel>
                            </IonCol>
                            <IonCol sizeXs= "12" sizeXl="4" sizeSm = "12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IonButton color={"danger"} size="small" onClick={() => deleteItem(orderItem)}>Entfernen</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                </IonCol>
            </IonRow>
        </IonCard>
    );
}