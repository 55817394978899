import React from 'react';
import { useSelector } from 'react-redux';

export default function CartCounter() {

    const shoppingCart = useSelector(state => state.shoppingCart.shoppingCartState);

    return(
        <div className = "counter-cart"><p>{shoppingCart.length}</p></div>
    )
}