import { createSlice } from "@reduxjs/toolkit";

const shoppingCartSlice = createSlice({
    name: "shoppingCart",
    initialState: {
        shoppingCartState: [] //initiallized as an empty array
    },
    reducers: {
        setShoppingCart: (state, action) => {
            state.shoppingCartState = action.payload || [];  
        },
        deleteItemCart: (state, action) => {
            state.shoppingCartState = state.shoppingCartState.filter(item => item.id !== action.payload.id);
        }
    }
});

export const { setShoppingCart, deleteItemCart } = shoppingCartSlice.actions;
export default shoppingCartSlice.reducer;
